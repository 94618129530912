.add_light_text {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #212b36;
}

.tab_header {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #637381;
  text-transform: none;
}

.tab_header_selected {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #00a94f;
  text-transform: none;
}

.ml-16 {
  margin-left: 16px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-7 {
  margin-right: 7.25px !important;
}
