.main-container {
  text-align: left;
  width: 1150px !important;
  margin: auto;
  background: #ffffff;
  border-radius: 30px;
}
.datapoint-name-input-box {
  width: 950px;
  height: 50px;
  margin: 20px auto !important;
}
.datapoint-name-input-box-description {
  width: 950px;
  /* height: 120px; */
}
.datapoint-name-input-box-description > div {
  /* height: 100%; */
}
.datapoint-name-input-box-description > div > textarea {
  /* height: 120px !important; */
  min-height: 120px !important;
  max-height: 120px !important;
  /* overflow: scroll !important; */
}
.data-point-input-box-container {
  margin: auto !important;
}
.title-container,
.data-point-input-box-container,
.datapoint-add-button-classname {
  margin: auto;
  width: 950px !important;
}
.datapoint-add-button-classname {
  margin: 50px auto;
}
.title-container > h1 {
  font-weight: 700;
  font-size: 32px;
}
.title-container > p {
  font-weight: 300;
  font-size: 18px;
}
.datapoint-add-button-classname {
  text-align: right;
}
.datapoint-add-button {
  width: 172px !important;
  height: 48px !important;
  text-transform: none !important;
  color: #c09507 !important;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  background: #ffffff !important;
  border: 2px solid #c09507 !important;
  box-shadow: none !important;
}
.datapoint-add-button:hover {
  background: #c09507 !important;
  color: #ffffff !important;
}
.finish-later-button {
  width: 172px !important;
  height: 48px !important;
  text-transform: none !important;
  color: #c09507 !important;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  background: #ffffff !important;
  /* border: 2px solid #c09507 !important; */
  box-shadow: none !important;
  border: none !important;
  color: black;
  background-color: #ffffff !important;
}
.finish-later-button:hover {
  border: 2px solid #c09507 !important;
}

.attribute-container {
  width: 950px;
  margin: auto;
}
.attribute-accordion-titile :nth-child(1) {
  width: 90% !important;
}
.datapoint-name-input-box input {
  height: 15px !important;
}
.attribute-container > h1 {
  font-weight: 700;
  font-size: 32px;
  margin: 20px auto;
}
.accordion-title {
  font-weight: 700 !important;
  font-size: 20px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.standardization-accordion-description {
  word-wrap: break-word;
  text-align: left;
}
.datapoint-attribute-input-box {
  width: 100%;
  /* margin: 0 20px 20px 0 !important; */
}
.datapoint-attribute-input-box1 {
  width: 100%;
}

.accordion-main-classname {
  box-shadow: 0px 20px 40px -4px rgba(145, 158, 171, 0.16) !important;
}
#add-attribute-icon-id {
  color: black !important;
  /* font-size: small; */
}
.add-datapoint-svg {
  cursor: pointer !important;
  text-align: left;
}
.update-category-button {
  width: 90px !important;
  height: 30px !important;
  text-transform: none !important;
  color: #c09507 !important;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  background: #ffffff !important;
  border: 2px solid #c09507 !important;
  box-shadow: none !important;
}
.reset-button {
  margin: 50px auto;
  text-align: right;
}
