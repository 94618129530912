/* Add all the new ui css classes here and to use it just import the global module file and use it as variable */

.primary_color {
  color: #00a94f !important;
}

.primary_fontStyle {
  font-family: Montserrat !important;
}

.dark_color {
  color: #000000 !important;
}

.primary_background {
  background-color: #00a94f !important;
}

.primary_buttonSupport {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  /* height: 48px !important; */
  background: #00a94f !important;
  border: #00a94f !important;
  border-radius: 8px !important;
  align-items: center !important;
  padding: 14px !important;
  color: #fff !important;
  text-transform: none !important;
}
.primary_buttonSupport:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.outlined_button {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  height: 48px !important;
  border-radius: 8px !important;
  padding: 11px 38px !important;
  color: #00a94f !important;
  text-transform: none !important;
  border: 1px solid #00a94f !important;
  background-color: #ffffff !important;
}

.outlined_button:active {
  color: #00a94f !important;
  border: 1px solid #00a94f !important;
  background-color: #ffffff !important;
}

.white_background {
  background: #ffffff !important;
}

.light_text {
  color: #919eab !important;
}

.highlighted_text {
  color: #00a94f !important;
}
.highlighted_text_in_home {
  color: white !important;
}

.title_text {
  color: #00a94f !important;
}

.bold300 {
  font-weight: 300 !important;
}

.bold400 {
  font-weight: 400 !important;
}

.bold500 {
  font-weight: 500 !important;
}

.bold600 {
  font-weight: 600 !important;
}

.bold700 {
  font-weight: 700 !important;
}

.size12 {
  font-size: 12px !important;
}

.size14 {
  font-size: 14px !important;
}

.size16 {
  font-size: 16px !important;
}

.size18 {
  font-size: 18px !important;
}

.size20 {
  font-size: 20px !important;
}

.size22 {
  font-size: 22px !important;
}

.size24 {
  font-size: 24px !important;
}

.size26 {
  font-size: 26px !important;
}

.size28 {
  font-size: 28px !important;
}

.size30 {
  font-size: 30px !important;
}

.size32 {
  font-size: 32px !important;
}

.size34 {
  font-size: 34px !important;
}

.size36 {
  font-size: 36px !important;
}

.size38 {
  font-size: 38px !important;
}

.size40 {
  font-size: 40px !important;
}

.size45 {
  font-size: 45px !important;
}

.size64 {
  font-size: 64px !important;
}

.blue {
  color: #3366ff;
}

.padding0 {
  padding: 0 !important;
}

.font_family {
  font-family: Montserrat !important;
}

/* all buttons */
.primary_button {
  background: none !important;
  background-color: #00a94f !important;
  font-family: Montserrat !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 26px;
  outline: none !important;
  border: none !important;
  text-transform: none !important;
  cursor: pointer !important;
  margin: 0px !important;
  color: #ffffff !important;
  border-radius: 8px !important;
}

.primary_button:active {
  background-color: #00a94f !important;
  outline: none !important;
  border: none !important;
}

.primary_button:disabled {
  background: rgba(145, 158, 171, 0.24) !important;
  border-radius: 8px !important;
  color: #ffffff !important;
}

.secondary_button {
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  letter-spacing: 0px;
  font-weight: 700 !important;
  background-color: unset !important;
  color: #212b36 !important;
  text-transform: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  width: fit-content !important;
  margin: 0px !important;
}

.secondary_button:active {
}

.secondary_button:hover {
}

.secondary_button_error {
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  letter-spacing: 0px;
  font-weight: 700 !important;
  background-color: unset !important;
  color: #ff5630 !important;
  text-transform: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  width: fit-content !important;
  margin: 0px !important;
}

.tertiary_button {
  /* background: none !important;
  background-color: #00A94F !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  outline: none !important;
  border: none !important;
  text-transform: none !important;
  cursor: pointer !important; */
}

.error {
  color: red !important;
}
.textDescription {
  line-height: 27px !important;
  margin: auto !important;
  font-size: 16px !important;
  font-style: italic !important;
  color: #565c63 !important;
  text-align: left;
}
.break_word {
  word-break: break-all;
}
.ellipses {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.homeButtonWidth {
  width: 368px !important;
}
